<template>
  <div :class="{ 'md:grid md:cols-2': eSpot }">
    <div>
      <form-sign-up ref="formRef" @submit="handleSignUpSubmit" />
      <div v-if="showSignInCta" class="mt-6 flex items-center gap-2 text-sm">
        {{ $t.alreadyAMember }}
        <vf-link @click="openModalSignIn">
          {{ $t.signIn }}
        </vf-link>
      </div>
    </div>
    <!-- espot: below-sign-up -->
    <cms-section v-if="eSpot" :section="eSpot" class="pt-10 lg:mt-8 md:ml-4 md:mt-6" lazy-media />
  </div>
</template>

<script lang="ts" setup>
import type { FormSignUp as FormSignUpType } from '#components'

export type Props = {
  eSpot?: any
  disableModal?: boolean
}
export type Emits = {
  resolve: []
  signIn: []
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()
const { $gtm } = useNuxtApp()
const { collectInterestsAtSignUp, enableLoyaltyFeatures } = useFeatureFlags()
const { ModalSignIn, ModalInterests } = useDialogsStore()
const formRef = ref<InstanceType<typeof FormSignUpType>>()
const toast = useToaster()
const { $t } = useNuxtApp()
const {
  isInterestsDependingOnNewsletter,
  showSignInCta,
  toastCtaLoyaltyLink
} = useAppConfig().components.dialog.signUp

let enableGtmClose = true

const handleSignUpSubmit = (newsletterConsent) => {
  enableGtmClose = false
  emit('resolve')

  if (collectInterestsAtSignUp && (!isInterestsDependingOnNewsletter || newsletterConsent))
    return ModalInterests.open()

  toast.add({
    props: {
      title: $t.accountCreated,
      message: $t.completeProfile,
      type: 'success',
      ctas: [
        {
          label: $t.goToMyAccount,
          to: enableLoyaltyFeatures && toastCtaLoyaltyLink?.requiredLocales.includes(useLocale())
            ? '/account/loyalty'
            : '/account'
        }
      ]
    },
    delay: 5000
  })
}

const openModalSignIn = async () => {
  enableGtmClose = false
  emit('signIn')

  if (!props.disableModal) {
    ModalSignIn.open()
    // When we open the sign-in, close the sign-up
    emit('resolve')
  }
}
onUnmounted(() => {
  if (enableGtmClose) $gtm.push('authForm.onRegistrationClose', 'modal:single:none', 'ecom')
})
</script>
